import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [{
      path: '/category-select',
      name: 'category-select',
      component: Vue.component('reorderCategory', require('@/views/reorderCategory.vue').default)
    }
    ],
  });
  
