<template>
  <span class="observer" />
</template>

<script>
export default {
  props: ['options'],
  data: () => ({
    observer: null
  }),
  created() {
    this.$root.$refs.Preloader = this;
  },
  mounted() {
    const options = this.options || {}
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect")
      }
    }, options)
    this.observer.observe(this.$el)
  },
  methods: {
    destroyed: function() {
      this.observer.disconnect()
    }
  }
}
</script>
