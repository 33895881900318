var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-select select"},[_c('span',{staticClass:"category-select__span"},[_vm._v("ATENÇÃO: Para organizar a ordem de seus produtos nas categorias da sua loja você deve selecionar antes uma categoria específica:")]),_c('div',{staticClass:"custom-select-div"},[(_vm.manager === true)?_c('div',{staticClass:"custom-select manager",class:{ open: _vm.openStores },attrs:{"tabindex":_vm.tabindex}},[(_vm.selectedStore !== 'Escolha em qual loja deseja modificar a ordem')?_c('div',{staticClass:"close",on:{"click":function($event){return _vm.clearProducts('manager')}}}):_vm._e(),_c('div',{staticClass:"arrow",on:{"click":function($event){_vm.openStores = !_vm.openStores}}}),_c('div',{staticClass:"category-placeholder",on:{"click":function($event){_vm.openStores = !_vm.openStores}}},[_vm._v(" "+_vm._s(_vm.selectedStore)+" ")]),_c('div',{staticClass:"items",class:{ selectHide: !_vm.openStores }},_vm._l((_vm.stores),function(store){return _c('div',{key:store.site_id,on:{"click":function($event){_vm.selectedStore = store.loja_nome;
                    _vm.openStores = false,
                    _vm.clearProducts(),
                    _vm.changeStoreId(store.site_id)
                    _vm.getCategory(store.site_id)
                    _vm.selected = 'Digite uma categoria'}}},[_vm._v(" "+_vm._s(store.loja_nome)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"custom-select category",class:{ open: _vm.open },attrs:{"tabindex":_vm.tabindex}},[(_vm.selected !== 'Digite uma categoria')?_c('div',{staticClass:"close",on:{"click":function($event){return _vm.clearProducts(true)}}}):_vm._e(),_c('div',{staticClass:"arrow",on:{"click":function($event){_vm.open = !_vm.open}}}),_c('div',{staticClass:"category-placeholder",on:{"click":function($event){_vm.open = !_vm.open}}},[_vm._v(" "+_vm._s(_vm.selected)+" ")]),_c('div',{staticClass:"items",class:{ selectHide: !_vm.open }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.categoryNameSearchString),expression:"categoryNameSearchString"}],attrs:{"type":"text","placeholder":_vm.selected},domProps:{"value":(_vm.categoryNameSearchString)},on:{"input":function($event){if($event.target.composing){ return; }_vm.categoryNameSearchString=$event.target.value}}}),_vm._l((_vm.filteredCategorys),function(category){return _c('div',{key:category.id,on:{"click":function($event){_vm.selected = category.name;
                    _vm.open = false,
                    _vm.$emit('input', category.name),
                    _vm.changeCategoryId(category.id),
                    _vm.clearProducts(),
                    _vm.getProducts(_vm.categoryId, _vm.storeId)}}},[_vm._v(" "+_vm._s(category.path)+" ")])})],2)]),_c('div',{staticClass:"select-order"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectOrder),expression:"selectOrder"}],attrs:{"name":"ordenar","id":"order"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectOrder=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.clearProducts(),
            _vm.getOrderProduct()}]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Ordenar")]),_c('option',{attrs:{"value":"a-z"}},[_vm._v("Nome A-Z")]),_c('option',{attrs:{"value":"z-a"}},[_vm._v("Nome Z-A")]),_c('option',{attrs:{"value":"-price"}},[_vm._v("Menor Preço")]),_c('option',{attrs:{"value":"price"}},[_vm._v("Maior Preço")]),_c('option',{attrs:{"value":"recent"}},[_vm._v("Mais Recentes")]),_c('option',{attrs:{"value":"old"}},[_vm._v("Mais Antigos")]),_c('option',{attrs:{"value":"sold"}},[_vm._v("Mais Vendidos")])])]),_c('div',{staticClass:"active-inactive"},[_c('h4',{staticClass:"active-inactive__text"},[_vm._v("Ordenar")]),_c('fieldset',{attrs:{"id":"inputStatus"},on:{"change":function($event){_vm.clearProducts(),
        _vm.getOrderProduct()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputStatus),expression:"inputStatus"}],staticClass:"inputchecked",attrs:{"value":"active","type":"radio","name":"ChooseStatus"},domProps:{"checked":_vm._q(_vm.inputStatus,"active")},on:{"change":function($event){_vm.inputStatus="active"}}}),_c('label',{attrs:{"for":"active"}},[_vm._v("Ativo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputStatus),expression:"inputStatus"}],staticClass:"inputchecked",attrs:{"value":"inactive","type":"radio","name":"ChooseStatus"},domProps:{"checked":_vm._q(_vm.inputStatus,"inactive")},on:{"change":function($event){_vm.inputStatus="inactive"}}}),_c('label',{attrs:{"for":"inactive"}},[_vm._v("Inativo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputStatus),expression:"inputStatus"}],staticClass:"inputchecked",attrs:{"value":"","type":"radio","name":"ChooseStatus"},domProps:{"checked":_vm._q(_vm.inputStatus,"")},on:{"change":function($event){_vm.inputStatus=""}}}),_c('label',{attrs:{"for":"inactive"}},[_vm._v("Todos")])])])]),(_vm.totalProducts !== '')?_c('span',{staticClass:"totalProducts"},[_vm._v(_vm._s(_vm.totalProducts)+" produtos encontrados ")]):_vm._e(),_c('div',{staticClass:"line"}),_c('div',{staticClass:"help-text"},[_vm._m(0),(_vm.needSave === true)?_c('button',{staticClass:"save-button",class:{ desktop: !_vm.isMobile() },on:{"click":function($event){return _vm.saveOrder()}}},[_vm._v("Salvar")]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"help-text__span"},[_c('i'),_vm._v("Clique e arraste o produto que deseja mudar a ordem.")])}]

export { render, staticRenderFns }