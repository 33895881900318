// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App.vue";
import router from './routes';
import VueSweetalert2 from 'vue-sweetalert2';
import ApiService from './services/api.service';
import {isAuthenticated, jwtToken} from './modules/auth.module';

import "sweetalert2/dist/sweetalert2.min.css";

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

let debug = false;

let baseUrl = '';
let apiBaseUrl = '';

if (isAuthenticated) {
  let tokenParts = jwtToken.split('.');
  let decodedData = JSON.parse(window.atob(tokenParts[1]));
  debug = decodedData.debug;  // O debug é coletado do backend

  baseUrl = debug ? "http://" + decodedData.domain + ":8000" : "https://" + decodedData.domain;

  // Set the base URL of the API
  apiBaseUrl = debug ? "http://" + decodedData.domain + ":8000/api/admin/" : "https://" + decodedData.domain + "/api/admin/";
  ApiService.init(apiBaseUrl);
}

/* eslint-disable no-new */
new Vue({
  router: router,
  render: h => h(App)
}).$mount('#app');

export default baseUrl;