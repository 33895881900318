<template>
    <div class="category-select select">
        <span class="category-select__span">ATENÇÃO: Para organizar a ordem de seus produtos nas categorias da sua loja você deve selecionar antes uma categoria específica:</span>
        <div class="custom-select-div">
        <div class="custom-select manager"
            v-if="manager === true"
            :tabindex="tabindex" 
            :class="{ open: openStores }"
        >
            <div class="close"
                v-if="selectedStore !== 'Escolha em qual loja deseja modificar a ordem'"
                @click="
                    clearProducts('manager')
                "
            >
            </div>
            <div class="arrow"
                 @click="openStores = !openStores"
            >
            </div>
            <div @click="openStores = !openStores"
                class="category-placeholder"
            >
                {{ selectedStore }}
            </div>
            <div class="items" 
                :class="{ selectHide: !openStores }">
                <div
                    v-for="store in stores"
                    :key="store.site_id"
                    @click="
                        selectedStore = store.loja_nome;
                        openStores = false,
                        clearProducts(),
                        changeStoreId(store.site_id)
                        getCategory(store.site_id)
                        selected = 'Digite uma categoria'
                    "
                >
                    {{ store.loja_nome }}
                </div>
            </div>
        </div>
        <div class="custom-select category"
            :tabindex="tabindex" 
            :class="{ open: open }"
        >
            <div class="close"
                v-if="selected !== 'Digite uma categoria'" 
                @click="
                    clearProducts(true)
            ">
            </div>
            <div class="arrow"
                 @click="open = !open"
            >
            </div>
            <div @click="open = !open"
                class="category-placeholder"
            >
                {{ selected }}
            </div>
            <div class="items" 
                :class="{ selectHide: !open }">
                 <input type="text" :placeholder="selected" v-model="categoryNameSearchString" />
                <div
                    v-for="category in filteredCategorys"
                    :key="category.id"
                    @click="
                        selected = category.name;
                        open = false,
                        $emit('input', category.name),
                        changeCategoryId(category.id),
                        clearProducts(),
                        getProducts(categoryId, storeId)
                    "
                >
                    {{ category.path }}
                </div>
            </div>
        </div>

        <div class="select-order">
            <select @change="
                clearProducts(),
                getOrderProduct()
                " 
                name="ordenar" 
                id="order" 
                v-model="selectOrder">
                <option disabled value="">Ordenar</option>
                <option value="a-z">Nome A-Z</option>
                <option value="z-a">Nome Z-A</option>
                <option value="-price">Menor Preço</option>
                <option value="price">Maior Preço</option>
                <option value="recent">Mais Recentes</option>
                <option value="old">Mais Antigos</option>
                <option value="sold">Mais Vendidos</option>
            </select>
        </div>

        <div class="active-inactive">
            <h4 class="active-inactive__text">Ordenar</h4>
            <fieldset @change="
            clearProducts(),
            getOrderProduct()
            " 
            id="inputStatus">
                <input value="active" type="radio" name="ChooseStatus" v-model="inputStatus" class="inputchecked">
                <label for="active">Ativo</label>
                <input value="inactive" type="radio" name="ChooseStatus" v-model="inputStatus" class="inputchecked">
                <label for="inactive">Inativo</label>
                <input value="" type="radio" name="ChooseStatus" v-model="inputStatus" class="inputchecked">
                <label for="inactive">Todos</label>
            </fieldset>
        </div>

        </div>

        <span   class="totalProducts"
                v-if="totalProducts !== ''"
        >{{ totalProducts }} produtos encontrados
        </span>

        <div class="line"></div>
        <div class="help-text">
            <span class="help-text__span"><i></i>Clique e arraste o produto que deseja mudar a ordem.</span>
            <button v-if="needSave === true"
                 class="save-button"
                 :class="{ desktop: !isMobile() }"
                 @click="
                    saveOrder()
                 "
            >Salvar</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props: {
            default: {
                type: String,
                required: false,
                default: null,
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
        },
        data() {
            return {
                open: false,
                openStores: false,
                selected: 'Digite uma categoria',
                selectedStore: 'Escolha em qual loja deseja modificar a ordem',
                manager: false,
                stores: [],
                storeId: '',
                categoryId: '',
                categorys: [],
                categoryNameSearchString: "",
                needSave: false,
                totalProducts: '',
                selectOrder: "",
                inputStatus: "",
                getStatusInput: false
            }
        },
        created() {
            this.getStoreId()
            this.$root.$refs.Categoryselect = this
        },
        computed: {
            filteredCategorys() {
                var filtered = this.categorys
                var categoryNameSearchString = this.categoryNameSearchString

                if(!categoryNameSearchString) {
                    return filtered
                }

                filtered = filtered.filter(function(item){
                    if(item.path.toLowerCase().indexOf(categoryNameSearchString) !== -1) {
                        return item
                    }
                })
                return filtered
            },
        },
        methods: {
             isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            getStoreId() {
                axios
                    .get("/product/catalog/sites/")
                    .then((res) => {
                        if (res.data.manager === true) {
                            this.stores = res.data.lojas
                            this.manager = true
                        }else {
                            this.storeId = res.data.lojas[0].site_id
                            this.getCategory(this.storeId)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            getOrderProduct() {
                this.$root.$refs.Ordemvitrineproxy.consumedApi = 'loading'
                this.$root.$refs.Ordemvitrineproxy.getProducts(this.categoryId, this.storeId, this.selectOrder, this.inputStatus);
                this.needSave = true
            },
            getCategory(storeId) {
                axios
                    .get(`/product/catalog/categories/?site_id=${storeId}`)
                    .then((res) => {
                        this.categorys = res.data.categorias
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            changeStoreId: function(storeId) {
                this.storeId = storeId
            },
            changeCategoryId: function(categoryId) {
                this.categoryId = categoryId
            },
            getProducts: function(categoryId, storeId) {
                this.$root.$refs.Ordemvitrineproxy.consumedApi = 'loading'
                this.$root.$refs.Ordemvitrineproxy.getProducts(categoryId, storeId)
            },
            passProductId: function (productId) {
                this.$root.$refs.Ordemvitrineproxy.category_id = productId
                this.$root.$refs.Ordemvitrineproxy.loading = []
                this.$root.$refs.Ordemvitrineproxy.products = []
            },
            saveOrder() {
                var updateOrder = []
                this.$root.$refs.Ordemvitrineproxy.products.forEach(function(item, index) {
                    var product = {}
                    product.product_id = item.id
                    product.order = index
                    updateOrder.push(product)
                })
                axios
                    .post('/product/ordenate/products_order/', {
                        "category_id": this.categoryId,
                        "site_id": this.storeId,
                        "products_order": updateOrder
                    })
                    .then(() => {
                        this.$root.$refs.Ordemvitrineproxy.hasChange = false
                        this.needSave = false
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: 'Alterações salvas com sucesso',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                            heightAuto: false,
                        })
                        updateOrder = []
                    })
                    .catch((err) => {
                        console.log(err)
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            title: 'Um erro aconteceu, tente novamente mais tarde',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                            heightAuto: false,
                        })
                    })
            },
            clearProducts: function(closeIcon) {
                if(this.$root.$refs.Ordemvitrineproxy.hasChange === true) {
                    this.$swal.fire({
                        icon: 'question',
                        title: 'Voçê deseja salvar suas alterações?',
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Salvar',
                        denyButtonText: 'Não Salvar',
                        cancelButtonText: 'Cancelar',
                        position: 'top',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            this.saveOrder()
                        } else if (result.isDenied) {
                            this.selected = 'Digite uma categoria'
                            this.$root.$refs.Ordemvitrineproxy.consumedApi = false
                            this.$root.$refs.Ordemvitrineproxy.products = []
                            this.$root.$refs.Ordemvitrineproxy.hasChange = false
                            this.needSave = false
                            this.totalProducts = ''
                        } else {
                            this.$root.$refs.Ordemvitrineproxy.consumedApi = 'loading'
                            this.$root.$refs.Ordemvitrineproxy.getProducts(this.categoryId, this.storeId, this.selectOrder, this.inputStatus);
                        }
                    })
                }else {
                    if (closeIcon === true) {
                        this.selected = 'Digite uma categoria'
                    } else if (closeIcon === 'manager'){
                        this.selectedStore = 'Escolha em qual loja deseja modificar a ordem'
                        this.selected = 'Digite uma categoria'
                        this.categorys = []
                    }
                    this.$root.$refs.Ordemvitrineproxy.consumedApi = false
                    this.$root.$refs.Ordemvitrineproxy.products = []
                    this.needSave = false
                    this.totalProducts = ''
                }
            },
        },
    }
</script>

<style lang="scss">
.custom-select {
  position: relative;
  width: 90%;
  max-width: 410px;
  text-align: left;
  outline: none;
  height: 40px;
  line-height: 40px;
  margin: 20px 0 20px;
  background-color: #f3f3f3;
  border-radius: 6px;
  border: 1px solid #7c7c7c;
  color: #2f2f2f;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
}

.custom-select-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 80px;
    padding: 20px 0;
    @media screen and (min-width: 840px){
        flex-wrap: nowrap;
        padding: 0 20px;
    }

    .select-order {
        select {
            height: 40px;
            width: 170px;
            padding: 0 20px 0 10px;
            background: #f3f3f3;
            border-radius: 5px;
            color: #2f2f2f;
            font-size: 1rem;
        }   
    }
    .active-inactive {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        &__text {
            margin: 0 0 10px 0;
        }

        fieldset {
            display: flex;
            align-content: center;
            justify-content: center;
            column-gap: 10px;
            border: none;
            padding: 0;

            input {
                height: 20px;
                width: 20px;
                margin: 0;
                all:unset;
                border-radius: 50%;
                padding: 9px;
                position: relative;
                
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 25px;
                    height: 25px;
                    border: 1px solid #7066e0;
                    border-radius: 50%;
                }

                &:checked {
                    background: #7066e0;
                }
                
            }
            label {
                color: #2f2f2f;
                font-size: 1rem;
            }
        }
    }
}



.custom-select .close{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 35px;
    background: url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPgo8dGl0bGU+Y2xlYXI8L3RpdGxlPgo8cGF0aCBkPSJNMTguOTg0IDYuNDIybC01LjU3OCA1LjU3OCA1LjU3OCA1LjU3OC0xLjQwNiAxLjQwNi01LjU3OC01LjU3OC01LjU3OCA1LjU3OC0xLjQwNi0xLjQwNiA1LjU3OC01LjU3OC01LjU3OC01LjU3OCAxLjQwNi0xLjQwNiA1LjU3OCA1LjU3OCA1LjU3OC01LjU3OHoiPjwvcGF0aD4KPC9zdmc+Cg==)no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    display: block;
    z-index: 999;
}

.custom-select.open {
  border-radius: 6px 6px 0px 0px;
}

.custom-select input {
    width: calc(100% - 40px);
    height: 40px;
    border: none;
    margin: 10px;
    padding:0 10px;
}

.totalProducts {
    margin-bottom: 15px;
    display: block;
}

.custom-select input::placeholder {
    color: #2f2f2f;
    font-size: 14px;
}

.custom-select .arrow {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 24px;
  height: 24px;
  background: url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPgo8dGl0bGU+a2V5Ym9hcmRfYXJyb3dfZG93bjwvdGl0bGU+CjxwYXRoIGQ9Ik03LjQwNiA4LjU3OGw0LjU5NCA0LjU5NCA0LjU5NC00LjU5NCAxLjQwNiAxLjQwNi02IDYtNi02eiI+PC9wYXRoPgo8L3N2Zz4K)no-repeat 100%;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #7c7c7c;
  border-left: 1px solid #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  position: absolute;
  background-color: #F3F3F3;
  left: -1px;
  right: 0;
  z-index: 99;
  max-height: 190px;
  overflow-y: auto;
  width: 100%;
}

.custom-select .category-placeholder {
    height: 40px;
    display: flex;
    align-items: center;
    line-height: 1;
    overflow: hidden;
    width: calc(100% - 60px);
}

.custom-select .items div {
  color: #2f2f2f;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #b4b4b4;
  color: #ffffff;
}

.selectHide {
  display: none;
}

.line {
    display: block;
    widows: 100%;
    height: 6px;
    background: #F4F4F4;
}

.help-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    position: relative;
    flex-wrap: wrap;
}

.help-text__span {
    color: #3F51B5;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 10px;
    width: 100%;
}

.help-text .save-button{
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #7066e0;
    color: #fff;
    font-size: 1em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
    margin-top: 15px;
}

.help-text .save-button:hover {
    background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1))
}

.help-text i {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy41IiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMTcuNSAyMCI+CiAgPHBhdGggaWQ9InBhbl90b29sX0ZJTEwwX3dnaHQ0MDBfR1JBRDBfb3BzejQ4IiBkPSJNMTAuNzcxLDIwaDUuNTgzYTMuMDUyLDMuMDUyLDAsMCwwLDIuMjI5LS45MDYsMywzLDAsMCwwLC45MTctMi4yMTlWNS4xODhhMS43NzgsMS43NzgsMCwwLDAtMS43NzEtMS43NzEsMi4zODksMi4zODksMCwwLDAtLjQ0OC4wNDIuOC44LDAsMCwwLS4zODUuMTg4VjIuNjg3YTEuOTQ1LDEuOTQ1LDAsMCwwLS41MS0xLjM1NCwxLjYzNSwxLjYzNSwwLDAsMC0xLjI2LS41NjNBMS44NTEsMS44NTEsMCwwLDAsMTQuNi44NDRhMS41NTEsMS41NTEsMCwwLDAtLjQ3OS4yNEExLjYyNCwxLjYyNCwwLDAsMCwxMy41MS4yOTIsMS42NzcsMS42NzcsMCwwLDAsMTIuNTQyLDBhMS43MzksMS43MzksMCwwLDAtMS4yNi41MjEsMS42ODgsMS42ODgsMCwwLDAtLjUzMSwxLjI1di41NDJhLjk5MS45OTEsMCwwLDAtLjM4NS0uMjA4LDEuNjIzLDEuNjIzLDAsMCwwLS40NDgtLjA2MiwxLjcsMS43LDAsMCwwLTEuMjUuNTIxLDEuNywxLjcsMCwwLDAtLjUyMSwxLjI1VjEyLjlMNC44NTQsMTEuMDQyYS45MjUuOTI1LDAsMCwwLS43MjktLjA3MywxLjcsMS43LDAsMCwwLS42NjcuMzg1TDIsMTIuNjI1bDYuMTg4LDYuNDE3YTIuOCwyLjgsMCwwLDAsMS4xNzcuNzRBNC41NDYsNC41NDYsMCwwLDAsMTAuNzcxLDIwWm0wLTEuMjVhNC41LDQuNSwwLDAsMS0uODY1LS4wODMsMS4zNTksMS4zNTksMCwwLDEtLjcxOS0uMzc1TDMuODc1LDEyLjc3MWwuNTYzLS41MjFoMEw5LjQsMTQuOTM3VjMuODEyYS41MjEuNTIxLDAsMSwxLDEuMDQyLDBWMTBIMTJWMS43NzFhLjUyMS41MjEsMCwxLDEsMS4wNDIsMFYxMEgxNC42VjIuNTQyYS41MjEuNTIxLDAsMSwxLDEuMDQyLDBWMTBoMS41NjJWNS4xODhhLjUyMS41MjEsMCwxLDEsMS4wNDIsMFYxNi44NzVhMS44LDEuOCwwLDAsMS0uNTUyLDEuMzMzLDEuODQ3LDEuODQ3LDAsMCwxLTEuMzQ0LjU0MkgxMC43NzFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMikiIGZpbGw9IiM5OTkiLz4KPC9zdmc+Cg==)no-repeat 100%;
    width: 17.5px;
    height: 20px;
    display: inline-block;
}

.help-text .save-button.desktop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-top: 0;
}
</style>