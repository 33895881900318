<template>
  <div class="reorderCategory">
    <categoryselect></categoryselect>
    <ordemvitrineproxy></ordemvitrineproxy>
  </div>
</template>

<script>
import Ordemvitrineproxy from '@/components/ordemvitrineproxy/Ordemvitrineproxy'
import Categoryselect from '@/components/ordemvitrineproxy/Categoryselect'

export default {
  name: 'reorderCategory',
  components: {
    Ordemvitrineproxy,
    Categoryselect
  },
}
</script>

<style>
body,
html {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  height: 100%;
}
</style>
