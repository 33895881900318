import ApiService from '../services/api.service';

let isAuthenticated = false;

let urlParams = new URLSearchParams(window.location.search);
let jwtToken = urlParams.get('token');

if (jwtToken) {
    ApiService.setHeader('Bearer ' + jwtToken);
    isAuthenticated = true;
}

export {isAuthenticated, jwtToken};