import axios from 'axios';
let cancel = [];

const ApiService = {

    init(apiBaseUrl) {
        axios.defaults.baseURL = apiBaseUrl;
    },

    setHeader(jwtToken) {
        axios.defaults.headers.common['Authorization'] = jwtToken;
    },

    removeHeader() {
        axios.defaults.headers.common = {};
    },

    get(resource, data) {
      let CancelToken = axios.CancelToken;
      let cancelToken;

      let gt = function() {

        if (cancel[resource] != undefined) {
           cancel[resource]();
         }

         if (data) {
            data.cancelToken = new CancelToken(function executor(c) {
              cancel[resource] = c;
            });
          }
        };

        gt();

        return axios.get(resource, data, cancelToken)
    },

    post(resource, data) {
        return axios.post(resource, data);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    }
};

export default ApiService;
